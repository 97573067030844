import {useState, useEffect, createContext, useContext} from "react";
import { Link } from "react-router-dom";
import {BrowserRouter as Router, Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import PaginationComponent from "components/Pagination/Pagination";
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Button,
  } from "reactstrap";
  // core components
  import PageHeader from "components/Headers/PageHeader.js";
  import listCustomers from "api/customer/listCustomers"
  import ModalDialog from "components/ModalDialog/ModalDialog";
  import AddCustomer from "./AddCustomer";
  import Alert from "components/Alerts/Alert";
  import { getFormattedTime } from "utils/utils";
  import { constants } from "assets/constants/constants";
import { getLCMID } from "api/company/getLCMID";
import deleteCustomer from "api/customer/deleteCustomer";
import Filter from "components/Filter/Filter.js";
  
  const Customers = () => {
    const [customers, setCustomers] = useState([]);
    const {token, setToken} = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const [modalCustomersState, setModalCustomersState] = useState(false);
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState(null);
    const [startIndex, setStartIndex] = useState(1)
    const [endIndex, setEndIndex] = useState(1 + constants["ROWS_PER_PAGE"])
    const [deleteCustomerResponse, setDeleteCustomerResponse] = useState(null)

    const doGetListCustomers = async () => {
        try{
            let currentCustomers = await listCustomers(token, lawnManagementCompanyId, 0, 100);
            setCustomers(currentCustomers);
        } catch (error) {
            console.log("Error on List Customers:", error);
        }
    }

    function onAddCustomerSuccess(message){
      setModalCustomersState(false);
      setAlert(message);
      setAlertColor("success")

      //reload
      doGetListCustomers();
    }

    function onDeleteCustomerClicked(customerId){
      doDeleteCustomerId(customerId)
    }

    async function doDeleteCustomerId(customerId){
      try {
        let rsp = await deleteCustomer(token, customerId)
        setDeleteCustomerResponse(rsp)
      } catch (error) {
        console.log("Error on delete customer:", error)
      }
    }

    function onCustomerDetailsClick(customerId){

      const propsToPass = {
        initial_property_id: customerId,
      };
      navigate('/admin/customer/detail/' + customerId, { state: propsToPass });
    }

    function onAddCustomerClick(){
      setModalCustomersState(true);
    }

    function getCustomerName(customer){
        return customer["customer"]["name"]
    }

    function getCustomerEmail(customer){
      if(customer["customer"]["customer_type"] === "company"){
        return customer["associate"]["company_email"];
      } else {
        return customer["associate"]["email"];
      }
    }

    useEffect(() => {
      if(deleteCustomerResponse){
        if(deleteCustomerResponse.success){
          doGetListCustomers()
          setAlertColor("success")
        } else {
          setAlertColor("danger")
        }
        setAlert(deleteCustomerResponse.message)
      }
    },[deleteCustomerResponse])


    async function getLawnManagementId () {
      setLawnManagementCompanyId(await getLCMID(token))
    }

    useEffect(() => {
      if(token){
          getLawnManagementId()
      }
    }, [token])

    useEffect(() => {
      if(lawnManagementCompanyId){
        doGetListCustomers();
      }
    }, [lawnManagementCompanyId])

    const [filteredCustomers,setFilteredCustomers]=useState([])
    const [customersNames,setCustomersNames]=useState([])
    const [finalCustomers,setFinalCustomers]=useState([])

    useEffect(()=>{
      if(customers && customers.data){
        const newCustomersArray = customers.data.map((item) => item.customer);
        setCustomersNames(newCustomersArray);
      }
    },[customers])

    useEffect(() => {
      if (filteredCustomers?.length && customers?.data?.length) {
          const combinedCustomers = customers.data.reduce((acc, cust) => {
              const matchedCustomer = filteredCustomers.find(filtCust => filtCust.id === cust.customer.id);
              if (matchedCustomer) {
                  acc.push({ associate: cust.associate, customer: matchedCustomer });
              }
              return acc;
          }, []);
  
          setFinalCustomers(combinedCustomers);
      }else{
        setFinalCustomers([]);
      }
  }, [filteredCustomers, customers]);

    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          {alert&&
            <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
          }
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex modal-header">
                  <h3 className="mb-0">Customers</h3>
                  <Button
                        color="primary"
                        onClick={(e) =>{e.preventDefault(); onAddCustomerClick();}}
                        size="sm"
                        
                        >
                       + Add
                        </Button>
                </CardHeader>
                <div style={{margin:"0px 0px 10px 15px"}}>
                  <Filter elements={customersNames} setFilteredElements={setFilteredCustomers} filterBy={"name"}></Filter>
                </div>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Email</th>
                      <th scope="col">Billing Address</th>
                      <th scope="col">Created At</th>
                      <th scope="col" className="text-right">View | Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalCustomers && finalCustomers.slice(startIndex, endIndex).map((customer,idx) => (
                        <tr id={customer["customer"]["id"]} key={idx}>
                        <th scope="row" style={{maxWidth:"25vw", overflow:"hidden", textOverflow:"ellipsis"}}>
                        {getCustomerName(customer)}
                        </th>
                        <td>
                          {customer["customer"]["customer_type"] == "natural"? "Home owner" : "Company"}
                        </td>
                        <td>
                         {getCustomerEmail(customer)}
                        </td>
                        <td>
                          {customer["customer"]["delivery_address"] + " " +
                           customer["customer"]["delivery_city"] + " " + customer["customer"]["delivery_state"]}
                        </td>
                        <td>
                          {getFormattedTime(customer["customer"]["created_at"])}
                        </td>
                        <td className="text-right">
                            <Button
                              className="btn-icon-only text-primary"
                              role="button"
                              size="md"
                              color=""  
                              onClick={() => onCustomerDetailsClick(customer["customer"]["id"])}
                            >
                              <i className="fas fa-eye" />
                            </Button>
                          <Button
                              color=""
                              className="btn-icon-only text-danger"
                              type="button"
                              size="md"
                              onClick={()=>onDeleteCustomerClicked(customer["customer"]["id"])}
                              
                          >
                            <i className="fa-solid fa-trash"></i>
                            </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  
                </Table>
                {customers && customers.success && customers["data"] && finalCustomers &&
                  <div className="mt-3">
                    <PaginationComponent data={finalCustomers} itemsPerPage={constants["ROWS_PER_PAGE"]} getStart={setStartIndex} getEnd={setEndIndex}/>
                  </div>
                }
              </Card>
            </div>
          </Row>
        </Container>
        {modalCustomersState &&
            <ModalDialog 
                size="lg"
                status={modalCustomersState} 
                setter={setModalCustomersState}
                title={"Add Customer"}
                content={<AddCustomer setSuccess={(message) => onAddCustomerSuccess(message)}/>}
            />
        }
        </>
    );
  };
  
  export default Customers;


    