
import {useState, useEffect} from "react";

import {
  Input,
  Row,
  Col,
  FormGroup,
  Button,
} from "reactstrap";

import addContact from "api/customer/addContact";
import getUserByUsername from "api/users/getUser";
import { useTokenContext } from "App";
import ValidationInput from "components/ValidationInput/ValidationInput";
import Alert from "components/Alerts/Alert"

function AddContact({customerId, setSuccess}){
  const {token, setToken} = useTokenContext();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [nameVisible, setNameVisible] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [addContactResponse, setAddContactResponse] = useState(false);
  const [alert, setAlert] = useState(null);

  const [emailValid, setEmailValid] = useState(true);
  const [firstValid, setFirstValid] = useState(true);
  const [lastValid, setLastValid] = useState(true);
  const [mobileValid, setMobileValid] = useState(true)

  function onEmailBlur(){
    //check email exists
    doCheckUserByUsername();
  }

  function onAddClick(){
    if(email === "" || firstName === "" || lastName === ""|| mobile === ""){
        setAlert("Complete all mandatory fields (*)")
    } else {
        doAddContact();
    }
  }

  const doCheckUserByUsername = async () => {
    try {
        let usrEx = await getUserByUsername(token, email);
        setUserExists(usrEx);
    } catch (error) {
        console.log("Error on Check User By UserName:", error);
    }
  }

  const doAddContact = async () => {
    try {
        if(userExists.success == true){
            let response = await addContact(customerId, email, firstName, lastName, mobile, token);
            setAddContactResponse(response)
        } else {
            let response = await addContact(customerId, email, firstName, lastName, mobile, token);
            setAddContactResponse(response)
        }

    } catch (error) {
        console.log("Error on Add Contact:", error);
    }
  }

  useEffect(() => {
    if(userExists){
        if(userExists.success == true){
            setNameVisible(true);
            setFirstName(userExists["data"]["first_name"]);
            setLastName(userExists["data"]["last_name"])
        } else {
            setNameVisible(false);
            // setFirstName("");
            // setLastName("")

        }
    }
  }, [userExists]);

  useEffect(() => {
    if(addContactResponse){
        if(addContactResponse.success){
            setSuccess(addContactResponse.message);
        } else {
            setAlert(addContactResponse.message)
        }
    }

  }, [addContactResponse])
  
  return (
    <>
        <div className="pl-lg-4">
            {alert&&
                <Alert color="danger" text={alert} setter={setAlert}></Alert>
            }
                <Row>
                <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-email"
                    >
                        Email
                        <span>
                        <small style={{ color: "red", borderColor: "red" }}>
                            &nbsp;*
                        </small>
                        </span>
                    </label>
                    <ValidationInput type="email" placeholder="Email" value={email} setter={setEmail} validationType="EMAIL" message={"Invalid email"} mandatory onBlur={onEmailBlur} getValidState={setEmailValid}/>
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-email"
                    >
                        Mobile
                        <span>
                        <small style={{ color: "red", borderColor: "red" }}>
                            &nbsp;*
                        </small>
                        </span>
                    </label>
                    <ValidationInput type="text" placeholder="Mobile" value={mobile} setter={setMobile} validationType="FREETEXT" message={"Invalid mobile number"} mandatory getValidState={setMobileValid}/>
                    </FormGroup>
                </Col>
                </Row>
                <Row>
                <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-modal"
                    >
                        First Name
                        <span>
                        <small style={{ color: "red", borderColor: "red" }}>
                            &nbsp;*
                        </small>
                        </span>
                    </label>
                    <ValidationInput type="text" placeholder="First Name" disabled={nameVisible} value={firstName} setter={setFirstName} validationType="PERSONNAME" message={"Invalid first name"} mandatory resetInvalid={nameVisible} getValidState={setFirstValid}/>
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-last-modal"
                    >
                        Last Name
                        <span>
                        <small style={{ color: "red", borderColor: "red" }}>
                            &nbsp;*
                        </small>
                        </span>
                    </label>
                    <ValidationInput type="text" placeholder="Last Name" disabled={nameVisible} value={lastName} setter={setLastName} validationType="PERSONNAME" message={"Invalid last name"} mandatory resetInvalid={nameVisible} getValidState={setLastValid}/>
                    </FormGroup>
                </Col>
                </Row>
        </div>
        <div className="pl-lg-4 pt-3">
        <Button 
        color="primary" 
        disabled={!(emailValid&&firstValid&&lastValid&&mobileValid)}
        type="button" 
        onClick={(e) => {e.preventDefault(); onAddClick()}}>
            Add user
        </Button>
        </div>
    </>
    );
};

export default AddContact;
