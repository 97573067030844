import {useState, useEffect, createContext, useContext} from "react";
import { Link } from "react-router-dom";
import {BrowserRouter as Router, Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTokenContext } from "App"
import { useParams } from 'react-router-dom';
import { getFormattedTime } from "utils/utils";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Table,
    CardBody,
    UncontrolledTooltip
  } from "reactstrap";
  // core components
  import PageHeader from "components/Headers/PageHeader.js";
  import Alert from "components/Alerts/Alert";

import getHole from "api/property/getHole";
import { DatePicker } from "reactstrap-date-picker";
import BreadcrumbNavigation from "components/Breadcrumb/BreadcrumbNavigation";
import getProperty from "api/property/getProperty";
import { getLCMID } from "api/company/getLCMID";
import AddApplicationProducts from "./AddApplicationProducts";
import getApplication from "api/property/getApplication";
import getRound from "api/rounds/getRound";
import listProducts from "api/products/listProducts";
import addProduct2application from "api/products/addProduct2Application";
import deleteProduct2Application from "api/products/deleteProduct2Application";
import updateProduct2Application from "api/products/updateProduct2Application";
import { updateApplication } from "api/property/updateApplication";
  
  const ConfigureUnscheduledApplication = () => {

    const {token, setToken} = useTokenContext();
    const [lawnManagementCompanyId, setLawnManagementCompanyId] = useState(null);
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [alertColor, setAlertColor] = useState(null);
    const { id } = useParams();

    const [application, setApplication] = useState(null);

    const [products, setProducts] = useState(null);
    const [productsNames, setProductsNames] = useState(null);
    const [datePicker, setDatePicker]= useState(new Date().toISOString())
    const [currentProductsFertiliser, setCurrentProductsFertiliser] = useState([]);
    const [currentProductsWeed, setCurrentProductsWeed] = useState([]);
    const [currentProductsPest, setCurrentProductsPest] = useState([]);
    const [hole, setHole] = useState(null);
    const [property, setProperty] = useState(null);
    const [fertiliserExistent, setFertilizerExistent] = useState([])
    const [weedExistent, setWeedExistent] = useState([])
    const [pestExistent, setPestExistent] = useState([])
    const [associatedRound, setAssociatedRound] = useState(null)
    const [addProduct2ApplicationRsp, setAddProduct2ApplicationRsp] = useState(null)
    const [deleteProduct2ApplicationRsp, setDeleteProduct2ApplicationRsp] = useState(null)
    const [updateProduct2ApplicationRsp, setUpdateProduct2ApplicationRsp] = useState(null)
    const [nrOfProductsAdded, setNrOfProductsAdded] = useState(0)
    const [nrOfProductsDeleted, setNrOfProductsDeleted] = useState(0)
    const [nrOfProductsUpdated, setNrOfProductsUpdated] = useState(0)
    const [updateApp, setUpdateApp] = useState(null)

    const doUpdateApplication = async (date, qt_check, ignore) => {
        try{
            let rsp = await updateApplication(token, application["data"]["nutrients_application"]["id"], "", "",date, qt_check);
            setUpdateApp(rsp);
        } catch (error) {
            console.log("Error on Update Application:", error);
        } 
      };  


       const doAddProduct2Application = async (prodId, quantity, carrier_volume) => {
        try {
           let rsp = await addProduct2application(token, application["data"]["nutrients_application"]["id"],prodId, parseFloat(quantity)*hole["data"]["hole"]["sq_ft"]/1000, parseFloat(carrier_volume))
           setAddProduct2ApplicationRsp(rsp)
           
        } catch (error) {
            console.log("Error on add product 2 program round: ", error)
        }
    }

    const doDeleteProduct2Application = async (p2a) => {
        try {
           let rsp = await deleteProduct2Application(token, p2a)
           setDeleteProduct2ApplicationRsp(rsp)
           
        } catch (error) {
            console.log("Error on delete product 2 program round: ", error)
        }
    }

    const doUpdateProduct2Application = async (prodId, quantity, carrier_volume) => {
        try {
           let rsp = await updateProduct2Application(token, prodId, application["data"]["nutrients_application"]["id"], parseFloat(quantity)*hole["data"]["hole"]["sq_ft"]/1000, parseFloat(carrier_volume))
           setUpdateProduct2ApplicationRsp(rsp)
           
        } catch (error) {
            console.log("Error on add product 2 program round: ", error)
        }
    }

    async function processAddProductsSequentially(products) {
        if (products.length === 0) {
            // Dacă nu mai sunt produse de procesat, ieșim din funcție recursivă
            return;
        }
    
        const entry = products[0];
        console.log(entry)
        await doAddProduct2Application(entry.product_id, entry.quantity, entry.carrier_volume);
    
        // Procesăm restul produselor recursiv
        await processAddProductsSequentially(products.slice(1));
    }


    async function processDeleteProductsSequentially(products) {
        if (products.length === 0) {
            // Dacă nu mai sunt produse de procesat, ieșim din funcție recursivă
            return;
        }
    
        const entry = products[0];
        let p2pId = application["data"]["products"].filter((prod) => prod.product_id == products[0]["id"])[0]
        await doDeleteProduct2Application(p2pId.id);

        await processDeleteProductsSequentially(products.slice(1));
    }


    async function updateProductsSequentially(products) {
        if (products.length === 0) {
            // Dacă nu mai sunt produse de procesat, ieșim din funcție recursivă
            return;
        }
    
        const entry = products[0];
        await doUpdateProduct2Application(entry.product_id, entry.quantity, entry.carrier_volume);

        await updateProductsSequentially(products.slice(1));
    }

    function handleDateChange(value, formattedValue) {
        setDatePicker(value)
    }

    function onSaveChangesClicked(){
        let combinedList = [...currentProductsFertiliser, ...currentProductsWeed, ...currentProductsPest]
        let existentList = [...fertiliserExistent, ...weedExistent, ...pestExistent]

        if(application.data.nutrients_application.qt_check == 2){
            doUpdateApplication(null, 3)
        } 
        const productsAdded = combinedList.filter(product2 => 
            !existentList.some(product1 => product1.product.id === product2.product_id)
        );

        const productsUpdated = combinedList.filter(product2 => 
            existentList.some(product1 => product1.product.id === product2.product_id && (product1.rate !== product2.quantity|| String(product1.carrier_volume) != String(product2.carrier_volume)))
        );

        const productsRemoved = existentList.map((prod) => prod.product).filter(product1 => 
            !combinedList.some(product2 => product2.product_id === product1.id)
        );
        setNrOfProductsAdded(productsAdded.length)
        setNrOfProductsDeleted(productsRemoved.length)   
        setNrOfProductsUpdated(productsUpdated.length)       

        console.log(productsAdded)
        console.log(productsRemoved)
        console.log(productsUpdated)
        processAddProductsSequentially(productsAdded) .then(() => {
            console.log("Toate produsele au fost adăugate în program secvențial.");
        })
        .catch(error => {
            console.error("Eroare în procesul de adăugare a produselor secvențial:", error);
        });
        processDeleteProductsSequentially(productsRemoved) .then(() => {
            console.log("Toate produsele au fost sterse în program secvențial.");
        })
        .catch(error => {
            console.error("Eroare în procesul de adăugare a produselor secvențial:", error);
        });

        updateProductsSequentially(productsUpdated) .then(() => {
            console.log("Toate produsele au fost updatate în program secvențial.");
        })
        .catch(error => {
            console.error("Eroare în procesul de adăugare a produselor secvențial:", error);
        });

        if(productsAdded.length == 0 && productsRemoved.length == 0 && productsUpdated.length == 0){
            const propsToPass = {
                pane: 3
              };
              navigate("/admin/round/configure/" + hole["data"]["hole"]["property_id"], { state: propsToPass });
        }

        if(datePicker!=new Date(application["data"]["nutrients_application"]["date"] * 1000).toISOString()){
            doUpdateApplication(parseInt(new Date(datePicker).getTime() / 1000))
        }

    }

      const doGetListProducts = async () => {
        try{
            let rsp = await listProducts(token, "true", lawnManagementCompanyId);
            setProducts(rsp);
        } catch (error) {
            console.log("Error on List Turf Types:", error);
        }
    }


    const doGetApplication = async () => {
        try{
            let appResp = await getApplication(token, id, 1);
            setApplication(appResp);
        } catch (error) {
            console.log("Error on Get Application:", error);
        }
    }

    const doGetHole = async () => {
        try{
            let rsp = await getHole(token, application["data"]["nutrients_application"]["hole_id"]);
            setHole(rsp);
        } catch (error) {
            console.log("Error on Get Hole:", error);
        }
    }

    const doGetProperty = async () => {
        try{
            let rsp = await getProperty(token, hole["data"]["hole"]["property_id"]);
            setProperty(rsp);
        } catch (error) {
            console.log("Error on Get Property:", error);
        }
    }

    const doGetRound = async () => {
        try{
            let rsp = await getRound(token, id);
            setAssociatedRound(rsp);
        } catch (error) {
            console.log("Error on Get Property:", error);
        }
    }

    useEffect(() => {
        if(addProduct2ApplicationRsp){
            if(addProduct2ApplicationRsp.success){
                setNrOfProductsAdded(prevState => prevState-1)
            } else {
                setAlert(addProduct2ApplicationRsp.message)
                setAlertColor("danger")
            }
        }
    },[addProduct2ApplicationRsp])

    useEffect(() => {
        if(deleteProduct2ApplicationRsp){
            if(deleteProduct2ApplicationRsp.success){
                setNrOfProductsDeleted(prevState => prevState-1)
            } else {
                setAlert(deleteProduct2ApplicationRsp.message)
                setAlertColor("danger")
            }
        }
    },[deleteProduct2ApplicationRsp])

    useEffect(() => {
        if(updateProduct2ApplicationRsp){
            if(updateProduct2ApplicationRsp.success){
                setNrOfProductsUpdated(prevState => prevState-1)
            } else {
                setAlert(updateProduct2ApplicationRsp.message)
                setAlertColor("danger")
            }
        }
    },[updateProduct2ApplicationRsp])

    useEffect(() => {
        if(application){
            if(application.data.products && productsNames && hole){
                application.data.products.forEach((product, idx) => {
                    if (productsNames[product.product_id].category === "fertiliser") {
                        setFertilizerExistent(prevState => [...prevState,  {"product": productsNames[product.product_id], "rate": product.quantity*1000/hole.data.hole.sq_ft, "carrier_volume": product.carrier_volume}]);
                    } else if (productsNames[product.product_id].category  === "weed_control") {
                        setWeedExistent(prevState => [...prevState, {"product": productsNames[product.product_id],"rate": product.quantity*1000/hole.data.hole.sq_ft, "carrier_volume": product.carrier_volume}]);
                    } else if (productsNames[product.product_id].category  === "pest_control") {
                        setPestExistent(prevState => [...prevState,  {"product": productsNames[product.product_id], "rate": product.quantity*1000/hole.data.hole.sq_ft, "carrier_volume": product.carrier_volume}]);
                    }
                });
            }
        }
    },[application, productsNames, hole])
    

    useEffect(() => {
        if(nrOfProductsAdded == 0 && nrOfProductsDeleted == 0 && nrOfProductsUpdated ==0 &&(addProduct2ApplicationRsp || deleteProduct2ApplicationRsp || updateProduct2ApplicationRsp || (updateApp && updateApp.success))){
            setAlert("Saved changes!")
            setAlertColor("success")
            const propsToPass = {
                pane: 3,
                message: "Saved changes"
              };
              navigate("/admin/property/detail/" + hole["data"]["hole"]["property_id"], { state: propsToPass });
        }
    },[nrOfProductsAdded, nrOfProductsDeleted, nrOfProductsUpdated,addProduct2ApplicationRsp,deleteProduct2ApplicationRsp, updateProduct2ApplicationRsp, updateApp])

    useEffect(() => {
        if(products && products.success && products["data"]!=null){
            setProductsNames(products["data"].reduce((acc,item) => {
                acc[item["id"]] = item
                return acc
                
            }, {}))
        }
    },[products])

    async function getLawnManagementId () {
        setLawnManagementCompanyId(await getLCMID(token))
      }
  
      useEffect(() => {
      if(lawnManagementCompanyId){
        doGetListProducts();
        doGetApplication();
        doGetRound();
      }
      }, [lawnManagementCompanyId])
  
      useEffect(() => {
          if(id, token){
              getLawnManagementId()
          }
      }, [id,token])


    useEffect(() => {
        if(application){
            if(application.success){
                setDatePicker(new Date(application["data"]["nutrients_application"]["date"] * 1000).toISOString())
                doGetHole();
            }
        }
    },[application])

    useEffect(() => {
        if(hole && hole.success ){
            doGetProperty();
        }
    },[hole])

    function navigateBack(){
        if(hole){
        const propsToPass = {
            pane: 3
          };
          navigate(-1, { state: propsToPass });
        }
    }
    return (
      <>
        <PageHeader />
        {/* Page content */}
        <Container className={alert ? "mt--8": "mt--7"} fluid>
            
            <div className="col">
            {alert&&
                        <Alert color={alertColor} text={alert} setter={setAlert}></Alert>
                        }
                <Row>
                    <Col lg="12" className="mb-2">
                        {hole && hole.success && hole.data && property && property.data &&
                    <BreadcrumbNavigation active="Round adjusments" past={[{"name":"Customers", "link":"/admin/customers"}, {"name": "Details", "link":"/admin/customer/detail/" + hole["data"]["hole"]["customer_id"]},
                    {"name": property["data"]["property"]["address"], "link":"/admin/property/detail/" + property["data"]["property"]["id"]}]}/>
                        }
                    </Col>
                </Row>
                        
                <Card className="bg-secondary shadow mb-5">

                    <CardHeader className="border-0 d-flex modal-header">
                        <div className="d-flex">
                        <Button
                        color="transparent"
                        onClick={(e) =>{e.preventDefault(); navigateBack()}}
                        size="sm"
                        >
                        <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                        <h3>{"Round adjusments"}</h3>
                        </div>
                        <Button
                            color="primary"
                            onClick={(e) =>{e.preventDefault(); onSaveChangesClicked()}}
                            disabled={!(hole)}
                            size="sm"
                            
                            >
                        Save changes
                        </Button>
                    </CardHeader>
                    <CardBody>
                    {application && application.success && hole &&
                    <>                      
                        <div className="pl-lg-4">
                        <Form>
                        <Row className="mt-0 justify-content-start align-items-center">
                        <Col lg="3">
                            <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-mailing-city"
                            >
                                Application Date
                                <span>
                                <small style={{ color: "red", borderColor: "red" }}>
                                    &nbsp;*
                                </small>
                                </span>
                            </label>
                            <DatePicker id  = "example-datepicker"
                            className=""
                                dateFormat="MM/DD/YYYY"
                                value   = {datePicker} 
                                onChange= {(v,f) => handleDateChange(v, f)}
                                style={{}}
                                
                                />
                                <small className="text-primary font-italic">
                                   {"Recommended application date: "+ getFormattedTime(associatedRound["data"]["application_date"])}
                                </small>
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                            <AddApplicationProducts adjustCarrier={true} round={associatedRound["data"]} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsFertiliser} productsType="fertiliser" title="Fertilization plan" currentProductsParent={fertiliserExistent} area={hole.data.hole.sq_ft}/>
                            </Col>

                            </Row> 
                            <Row className="mt-5">
                            <Col lg="12">
                            <AddApplicationProducts adjustCarrier={true} round={associatedRound["data"]} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsWeed} productsType="weed_control" title="Weed control plan" currentProductsParent={weedExistent} area={hole.data.hole.sq_ft}/>
                            </Col>
                            </Row>
                            <Row className="mt-5">
                            <Col lg="12">
                            <AddApplicationProducts adjustCarrier={true} round={associatedRound["data"]} setAlert={setAlert} setCurrentProductsParent={setCurrentProductsPest} productsType="pest_control" title="Pest control plan" currentProductsParent={pestExistent} area={hole.data.hole.sq_ft}/>
                            </Col>
                            </Row>
                            </Form>
                        </div>
                        </>
                    }
                    </CardBody>
                </Card>
            </div>
        </Container>
        </>
    );
  };
  
  export default ConfigureUnscheduledApplication;


    