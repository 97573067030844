import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';


function DropdownList({header, items, setter, disabled}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function onItemClick(item){
    setter(item);
  }


  return (
      <div style={{width: "100%"}}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{width: "100%"}} disabled={disabled}>
        <DropdownToggle caret style={{ width:"100%", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", backgroundColor:"White"}}>{header}</DropdownToggle>
        <DropdownMenu style={{maxHeight: "16vh", overflowY:"scroll", overflowX:"hidden", maxWidth:"25vw"}}>
          {items && items.map((item, index) => (
            <DropdownItem id={index} key={index} style={{textOverflowX:"ellipsis"}} onClick={() => onItemClick(item)}>{item}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default DropdownList;