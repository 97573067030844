import React from "react";
import {useState} from "react";
// reactstrap components
import {
  Modal,
} from "reactstrap";


function ModalDialog({size, status, setter, title, content}){
  const [open, setOpen] = useState(status);

  function setStatus(){
    setOpen(!open);
    setter(!open);
  }

  return (
    <Modal
    size={size}
    className="modal-dialog-centered"
    isOpen={open}
    toggle={setStatus}
    >
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
        {title}
      </h5>
      <button
        aria-label="Close"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={setStatus}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body pt-0">{content}</div>
    </Modal>
  );
};

export default ModalDialog;